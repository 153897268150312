import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CommonModule } from "@angular/common";

/*
*<div class="radio-template d-flex gap-16">
  <label *ngFor="let type of [0, 1]" [for]="type">
    <app-roam-radio-card-template [id]="type"
        [name]="type"
        [value]="type">
          <ng-container content>
            <!-- TEMPLATE -->
          </ng-container>
    </app-roam-radio-card-template>
   </label>
</div>
* */
@Component({
  selector: 'app-roam-radio-card-template',
  standalone: true,
  template:`
    <input
      class="radio-input"
      type="radio"
      [name]="name"
      [id]="id"
      [value]="value"
      [checked]="value === radioControl.value "
      (change)="onChange(value)"
    >
    <div class="radio-button">
      <ng-content select="[content]"></ng-content>
    </div>
  `,
  styles:[
      `
      .radio-button {
        min-width: 240px;
        border-radius: 9px;
        border: 1px solid rgba(143, 134, 147, 0.30);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        flex: 1;
      }
    `
  ],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RoamRadioCardTemplateComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RoamRadioCardTemplateComponent,
      multi: true
    }
  ],
  imports:[
    CommonModule
  ]
})
export class RoamRadioCardTemplateComponent {

  @Input()
  public id!: string | number | boolean;

  @Input()
  public name!: string | number;

  @Input()
  public value!: any;

  @Input()
  public selectedValue!: string | number ;

  @Output()
  public onChangeEvent: EventEmitter<any> = new EventEmitter<any>()

  public radioControl: FormControl = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.radioControl.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  writeValue(value: any): void {
    this.radioControl.setValue(value, { emitEvent: false });
  }

  onChange(value: any): void {
    this.writeValue(value);  // Update the internal control value
    this.onTouched();  // Trigger the touched callback
    this.onChangeEvent.emit(value)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.radioControl.disable() : this.radioControl.enable();
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.radioControl.valid ? null : { invalid: true };
  }

  private onTouched = () => {};
}
