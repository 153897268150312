<div class="d-flex gap-16 f-column ">
  <app-roam-input [name]="'Email'"></app-roam-input>
  <app-roam-input [name]="'Full Name'"></app-roam-input>
  <app-roam-input [name]="'Bank Account'"></app-roam-input>
</div>

<div class="set-grid set-grid-3 gap-16 mt-24">
  <div class="bank-card" *ngFor="let bank of [1, 2, 3, 4, 5, 6]">
    <img [src]="bankImage" alt="bank">
  </div>
</div>

<div class="font-12px mt-24">
  <span class="tc-primary">Enter bank details manually instead </span>
  <span class="tc-dust">(takes 1-2 business days)</span>
</div>

