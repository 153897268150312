import { dialogConfig } from '@app/core/const/dialog.const';
import { TextLabelDescComponent } from '@app/shared/components/roam-layout/text-label-desc/text-label-desc.component';
import { Component, inject, signal } from "@angular/core";
import { MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { RoamDialogTypeTwoComponent } from "../../roam-dialog/roam-dialog-type-two/roam-dialog-type-two.component";
import { CurrencyPipe } from "@angular/common";
import { SuccessPaymentComponent } from '../success-payment/success-payment.component';

@Component({
  selector: "review-payment",
  standalone: true,
  imports: [
    MatDialogModule, RoamDialogTypeTwoComponent, CurrencyPipe,
    TextLabelDescComponent
  ],
  template: `
    <app-roam-dialog-type-two [title]="'Review Payment'"
      [btnText]="'Submit Payment'"
      (onSave)="submitPayment()"
    >
      <div dialogContent>
        <header class="d-grid text-center fw-500 gap-24 tc-black">
          <h3 class="font-20px">Amount to Paid</h3>
          <h1 class="font-32px">
            {{ paidAmount() | currency }}
          </h1>
        </header>
        
        <div class="space">
          <h6 class="fw-500 mb-8 tc-black">Transaction Detail</h6>
          <table class="table-base">
            <colgroup>
              <col style="width: 80%;" />
              <col style="width: 20%;" />
            </colgroup>
            <tbody>
              <tr>
                <td>ashd823rbkajs 234234</td>
                <td>$190.00</td>
              </tr>
              <tr>
                <td>Convenience fee</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>CC service fee</td>
                <td>$5.00</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>$200.00</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="set-grid set-grid--3 stretch space">
          <app-text-label-desc 
            label="Payment date"
            [desc]="'Aug 17, 2023'"
            [black]="true"
          />
          <app-text-label-desc 
            label="Paid by"
            [desc]="'Marcus Rashford'"
            [black]="true"
          />
          <app-text-label-desc 
            label="Frequency"
            [desc]="'Payment recurs every month'"
            [black]="true"
          />
        </div>
        <p class="font-14px space tc-grey lh-normal text-justify">
          By clicking <span class="tc-black fw-500">Submit payment</span>, I authorize the above amount to be withdrawn from my account. 
          If any payments is returned or rejected, i may be charged additional fees. 
          I am aware all signatories are responsible for paying the lease. Payments are nonrefundable.
        </p>
        <div></div>
      </div>
    </app-roam-dialog-type-two>
  `,
  styles: `
    table {
      tr td {
        padding: 0.25rem 0;
        font-size: 14px;

        &:first-child {
          color: #777777;
        }

        &:last-child {
          text-align: right;
          color: #222222;
        }
      }

      tbody tr:last-child td {
        padding-bottom: 0.5rem;
      }

      tfoot tr td {
        border-top: 1px solid #cac0ce80;
        padding: 10px 0;
        color: #222222;
        font-weight: 500;
      }
    }
    .space {
        padding-top: 1.5rem;
      }
  `,
})
export class ReviewPaymentComponent {
  paidAmount = signal<number>(200);

  #dialog = inject(MatDialog)
  #dialogRef = inject(MatDialogRef);

  submitPayment(): void {
    this.#dialog.closeAll();
    this.#dialog.open(SuccessPaymentComponent, {
      ...dialogConfig.addDialogStd,
      minHeight: '480px'
    });
  }
}
