<app-roam-dialog-type-two [title]="'Make Payment'" (onSave)="makePayment()">
  <ng-container dialogContent>
    <div>
      <h6 class="font-14px fw-500 tc-black">Select an amount</h6>
      <div class="mt-8 mb-18">
        <div class="radio-template d-flex gap-16">
          <label for="balance">
            <input
              class="radio-input"
              type="radio"
              id="balance"
              value="balance"
              name="make-payment"
              [(ngModel)]="selectedPayment"
            >
            <div class="radio-button">
              <div class="fw-500 font-20px tc-black">$300.00</div>
              <span class="font-12px mt-12 tc-black">
                Current balance (as of Apr 21, 2024)
              </span>
            </div>
          </label>
          <label for="amount">
            <input
              class="radio-input"
              type="radio"
              id="amount"
              value="amount"
              name="make-payment"
              [(ngModel)]="selectedPayment"
            >
            <div class="radio-button">
              <roam-icon name="card-total" size="30"/>
              <span class="font-12px mt-12 tc-black">
                Other amount
              </span>
            </div>
          </label>
        </div>
      </div>
    </div>

    @if(selectedPayment === 'amount') {
      <div class="mt-8">
        <app-roam-input [name]="'Amount'" [currency]="true" />
      </div>
    }

    <hr class="mt-18">

    <div class="d-flex f-column gap-18">
      <app-roam-datepicker [name]="'Select a payment date'"></app-roam-datepicker>
      <div>
        <h6 class="title font-14px">Payment Method</h6>
        <payment-method-input (selectedMethod)="paymentMethod($event)"/>
      </div>
    </div>

    <hr class="mtb-18">

    <div class="d-inline-flex">
      <app-roam-toggle-slider [label]="'Enable Auto Payment'"></app-roam-toggle-slider>
    </div>

  </ng-container>
</app-roam-dialog-type-two>
