import { Component, Input } from '@angular/core';
import { IRoamTab } from "@app/shared/interfaces/roam-tab.interface";
import { screeningReports } from "@app/shared/components/payment/shared/payment.const";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-payment-screening',
  template:`
    <div>
      <h6 class="fw-600">Screening Report From <span class="tc-bclound">TransUnion</span>.</h6>
      <div class="tc-grey font-14px mt-8">
        In order to retrieve your credit information, we’ll need to verify your indentity with TransUnion. You’ll need to provide your full social security number and answer a few security questions. This reposrt is a soft inquiry and will not affect your credit report
      </div>
    </div>
    <div class="mt-32" *ngIf="showReport">
      <h6 class="fw-600">Report</h6>
      <div class="justify-between mt-16">
        <div class="d-flex gap-8">
          <div class="chip align-center gap-10"
               *ngFor="let chip of reportChips"
               [class.active]="chip.isActive">

            <span *ngIf="chip.isActive" class="d-flex"
                  [inlineSVG]="icon.checkTimes.checkWhite.src"
            ></span>
            <label>{{ chip.name }}</label>
          </div>
        </div>
        <div class="align-center gap-16">
          <label class="fw-500">Total</label>
          <h2 class="font-32px">$55</h2>
        </div>
      </div>
    </div>
  `
})
export class PaymentScreeningComponent {

  @Input()
  public showReport: boolean = true;

  public reportChips: IRoamTab[] = screeningReports;
  public icon = iconLib;

}
