<app-roam-dialog-type-one [title]="'Set up Autopay'">
  <ng-container dialogContent>
    <div class="mt-8">
      <app-roam-input [name]="'Amount Autopay'" [currency]="true"></app-roam-input>
    </div>

    <hr class="mt-18">

    <div class="d-flex f-column gap-18">
      <app-roam-select [placeholder]="'Initiate Payment'"></app-roam-select>
      <app-roam-select [placeholder]="'Frequency'"></app-roam-select>
      <div>
        <app-roam-select [placeholder]="'Select payment method'"></app-roam-select>
        <span class="font-10px tc-black">(2.99% service fee plus $10.00 convenience fee)</span>
      </div>
    </div>

  </ng-container>
</app-roam-dialog-type-one>
