import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDirectiveModule } from "@app/shared/directives/input-directive/input-directive.module";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  selector: 'app-input-credit-card',
  standalone: true,
  imports: [
    CommonModule,
    InputDirectiveModule,
    InlineSVGModule
  ],
  template:`
    <div class="credit-input">
      <span class="d-flex mr-10" [inlineSVG]="icon.ccInput.src"></span>
      <input appOnlyNumber
             class="card-number" type="text"
             placeholder="Card Number"
             [maxCharacter]="16">

      <input appCreditMmYy
             class="month-year" type="text"
             placeholder="MM/YY">

      <input appOnlyNumber type="text"
             class="cvv" placeholder="CVV"
             [maxCharacter]="3">
    </div>
  `,
  styles:[`
    .credit-input {
      padding: 12px 16px;
      border-radius: 4px;
      border: 0.5px solid rgba(143, 134, 147, 0.3);
      display: flex;
      align-items: center;
      width: calc(100% - 1rem);
    }

    .credit-input input {
      border: none;
      background-color: transparent;
      color: #949494;
      font-size: 14px;
    }

    .card-number {
      width: 60%;
    }

    .month-year {
      width: 30%;
    }

    .cvv {
      width: 10%;
    }
  `]
})
export class InputCreditCardComponent {
  public icon = iconLib;
}
