import { Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, inject, signal } from "@angular/core";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";

@Component({
  selector: "success-payment",
  standalone: true,
  imports: [MatDialogModule, CurrencyPipe, DatePipe, RoamButtonComponent, RoamIconComponent],
  template: `
    <h1 class="fw-500" mat-dialog-title>Thank you, all set!</h1>
    <div mat-dialog-content 
         class="gap-6px f-column justify-center gap-18 tc-black pb-40">
      <roam-icon [src]="false" name="credit-card-key"/>
      <h3 class="font-20px fw-500">You've paid</h3>
      <h1 class="font-32px fw-500">{{ paidAmount() | currency }}</h1>
      <p class="tc-grey font-14px">Paid on {{ paidDate | date }}</p>
    </div>
    <mat-dialog-actions 
      class="pr-28 mat-dialog-roam-action end gap-16">
      <app-roam-button 
        [class]="'btn-o-primary'"
        label="Print Receipt"
        (onClick)="printReceipt()"
      />
      <app-roam-button 
        label="Return to Dashboard"
        (onClick)="navigateToDashboard()"
      />
    </mat-dialog-actions>
  `,
  styles: ``,
})
export class SuccessPaymentComponent {
  paidAmount = signal<number>(200);
  paidDate = new Date();

  #dialogRef = inject(MatDialogRef<SuccessPaymentComponent>)
  #router = inject(Router);

  printReceipt(): void {
    console.log('[TODO] Print Receipt')
  }

  navigateToDashboard(): void {
    this.#dialogRef.close();
    this.#router.navigate(['./']);
  }
}
