import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentScreeningComponent } from './payment-screening/payment-screening.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { AddPaymentMethodComponent } from './add-payment-method/add-payment-method.component';
import { PaymentMethodCreditCardComponent } from './payment-method-credit-card/payment-method-credit-card.component';
import { RoamDialogModule } from "@app/shared/components/roam-dialog/roam-dialog.module";
import { RoamRadioCardComponent } from '../roam-radio-card/roam-radio-card.component';
import { InputCreditCardComponent } from '../input/input-credit-card/input-credit-card.component';
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { InputModule } from "@app/shared/components/input/input.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaymentMethodBankComponent } from './payment-method-bank/payment-method-bank.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { ButtonModule } from "@app/shared/components/button/button.module";
import { RoamRadioCardTemplateComponent } from '../roam-radio-card-template/roam-radio-card-template.component';
import { SetPaymentComponent } from './set-payment/set-payment.component';
import { NgxStripeModule, StripeCardComponent, StripeCardCvcComponent, StripeCardExpiryComponent, StripeCardGroupDirective, StripeCardNumberComponent, StripeElementsDirective, StripePaymentElementComponent } from 'ngx-stripe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RoamIconComponent } from '../roam-icon/roam-icon.component';
import { PaymentMethodInputComponent } from './payment-method-input/payment-method-input.component';
import { ReviewPaymentComponent } from './review-payment/review-payment.component';

@NgModule({
  declarations: [
    PaymentScreeningComponent,
    AddPaymentMethodComponent,
    PaymentMethodCreditCardComponent,
    PaymentMethodBankComponent,
    MakePaymentComponent,
    SetPaymentComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    InlineSVGModule,
    InputCreditCardComponent,
    InputModule,
    MatFormFieldModule,
    MatInputModule,
    NgxStripeModule,
    PaymentMethodInputComponent,
    ReactiveFormsModule,
    RoamDialogModule,
    RoamIconComponent,
    RoamLoaderModule,
    RoamRadioCardComponent,
    RoamRadioCardTemplateComponent,
    ReviewPaymentComponent,
    StripeCardComponent,
    StripeCardCvcComponent,
    StripeCardExpiryComponent,
    StripeCardGroupDirective,
    StripeCardNumberComponent,
    StripeElementsDirective,
    StripePaymentElementComponent,
    StripePaymentElementComponent,
  ],
  exports: [
    PaymentScreeningComponent,
    AddPaymentMethodComponent,
    RoamRadioCardTemplateComponent,
    PaymentMethodCreditCardComponent,
    MakePaymentComponent,
    SetPaymentComponent
  ]
})
export class PaymentModule { }
