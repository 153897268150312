import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { MakePayment } from '../interfaces/make-payment.interface';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  #apiUrl = `${environment.apiUrl}/stripe`;
  #http = inject(HttpClient)

  createPaymentIntent(payload: MakePayment): Observable<any> {
    return this.#http.post(`${this.#apiUrl}/create-payment-intent`, payload, {
      withCredentials: true
    })
  }
}
