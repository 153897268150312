import {
  Component,
  DestroyRef,
  inject,
  output,
  signal,
} from "@angular/core";
import { RoamDialogTypeTwoComponent } from "../../roam-dialog/roam-dialog-type-two/roam-dialog-type-two.component";
import { MatRadioModule } from "@angular/material/radio";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { TextLabelDescComponent } from "../../roam-layout/text-label-desc/text-label-desc.component";
import { PaymentMethod } from "@app/shared/interfaces/payment-method-list.interface";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
import { ButtonAddComponent } from "../../button/button-add/button-add.component";
import { LlcStripeDialogComponent } from "../../roam-dialog/llc-stripe-dialog/llc-stripe-dialog.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { AddPaymentMethodComponent } from "../add-payment-method/add-payment-method.component";
import { ButtonAddUnderlineComponent } from "../../button/button-add-underline/button-add-underline.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PaymentMethodService } from "@app/shared/services/payment-method.service";
import { share } from "rxjs";

@Component({
  selector: "select-payment-method",
  standalone: true,
  imports: [
    RoamDialogTypeTwoComponent,
    MatRadioModule,
    RoamIconComponent,
    TextLabelDescComponent,
    MatProgressSpinnerModule,
    FormsModule,
    ButtonAddUnderlineComponent
  ],
  template: `
    <app-roam-dialog-type-two [title]="'Choose Payment Method'" (onSave)="dialogRef.close(selected)">
      <div dialogContent>
        <mat-radio-group class="stretch select-option" [(ngModel)]="selected">
          @for (payment of paymentMethods(); track payment.id) {
            <mat-radio-button labelPosition="before" [value]="payment">
              <div class="method-list">
                <div class="align-center gap-16">
                  <div class="icon-bg">
                    <roam-icon
                      [src]="false"
                      [name]="'payment/' + payment.brand"
                      color="primary" />
                  </div>
                  <app-text-label-desc
                    [label]="payment.brand"
                    [desc]="
                      '••••' +
                      payment.last4 +
                      ' | ' +
                      payment.expMonth +
                      '/' +
                      payment.expYear
                    "
                    [black]="true"
                    classLabel="capitalized"
                    classDesc="tc-black fw-500" />
                </div>
                <div class="fw-500">
                  {{ payment.cardholderName }}
                </div>
                <div>
                  <div>
                    <span class="font-14px" [class.default]="payment.isDefault">
                      @if (payment.isDefault) {
                        <span>Default</span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </mat-radio-button>
          }
        </mat-radio-group>

        <div  class="mt-24">
          <app-button-add-underline label="Add New Method" (onClick)="addPayment()"/>
        </div>
      </div>
    </app-roam-dialog-type-two>
  `,
  styles: `
    .select-option {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr;
    }

    .method-list {
      display: grid;
      grid-template-columns: 50% 25% 25%;
      align-items: center;
      cursor: pointer;
    }

    .icon-bg {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: rgba(145, 70, 106, 0.1);
    }

    ::ng-deep .select-option .mdc-form-field {
      padding: 15px;
      border-radius: 8px;
      border: 1px solid #e8e4e9;
      cursor: pointer;
    }
  `,
})
export class SelectPaymentMethodComponent {
  save = output<PaymentMethod>();
  selected!: PaymentMethod;
  paymentMethods = signal<PaymentMethod[]>([]);

  dialogRef = inject(MatDialogRef<SelectPaymentMethodComponent>);
  readonly data = inject(MAT_DIALOG_DATA);
  readonly dialog = inject(MatDialog);
  destroyRef = inject(DestroyRef);
  service = inject(PaymentMethodService);

  constructor() {
    this.paymentMethods.set(this.data.payments);
  }

  addPayment(): void {
    this.dialog
      .open(LlcStripeDialogComponent, {
        ...dialogConfig.dialogStd,
      })
      .afterClosed()
      .subscribe(state => {
        if (state) {
          this.openPaymentMethodDialog();
        }
      });
  }

  openPaymentMethodDialog(): void {
    this.dialog
      .open(AddPaymentMethodComponent, {
        ...dialogConfig.addDialog,
        maxWidth: "700px",
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.#getPaymentMethods();
        }
      });
  }

  #getPaymentMethods(): void {
    this.service
      .paymentMethod()
      .pipe(takeUntilDestroyed(this.destroyRef), share())
      .subscribe({
        next: resp => {
          this.paymentMethods.set(resp);
        },
      });
  }
}
