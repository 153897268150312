import { IRoamTab } from "@app/shared/interfaces/roam-tab.interface";
import { IRadio } from "@app/shared/interfaces/roam-radio.inteface";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";

export enum PaymentMethod {
  BANK = 'bank',
  CARD = 'card'
}

export const screeningReports: IRoamTab[] = [
  {
    id: 'credit',
    name: 'Credit',
    isActive: true
  },
  {
    id: 'criminal-record',
    name: 'Criminal Record',
    isActive: true
  },
  {
    id: 'eviction',
    name: 'Eviction Proceeding',
    isActive: true
  }
]

export const paymentMethodCards: IRadio[] = [
  {
    id: 'credit-card',
    name: 'type-payment',
    label: 'Card',
    value: 'card',
    icon: './assets/svg/ic-credit-card.svg'
  }
]

export const confirmationDefaultPaymentText: IConfirmationDialog = {
  title: 'Make as Default',
  body: `
    <div class="fw-400 pt-8">Are you sure you want to make this payment method as default?</div>`,
  btnCancel: 'Cancel',
  btnSubmit: 'Yes, Continue'
}

export const confirmationDeletePaymentText: IConfirmationDialog = {
  title: 'Delete Payment Method',
  body: `
    <div class="fw-400 pt-8">Are you sure want to delete this payment method?</div>`,
  btnCancel: 'Cancel',
  btnSubmit: 'Yes, Delete'
}

export const PaymentMethodMessage = {
  successNewPaymentMethod : 'Payment method successfully created'
}