<app-roam-dialog-type-one [title]="'Add Payment Method'"
                          [btnText]="'Save Payment Method'"
                          [isLoading]="isSubmit()"
                          (onSave)="saveMethodPayment()"
>
  <ng-container dialogContent>
    <div>
      <div>
        <div class="mt-12">
          <app-roam-radio-card [options]="paymentMethods"
                               [labelType]="'std'"
                               [(ngModel)]="selectedMethod"
          />
        </div>
      </div>
      <div class="mt-24">
        @if(selectedMethod === 'card') {
          <app-payment-method-credit-card #creditPayment />
        } @else {
          <app-payment-method-bank #bankPayment />
        }
      </div>
    </div>
  </ng-container>
</app-roam-dialog-type-one>
