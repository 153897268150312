import { Component } from '@angular/core';

@Component({
  selector: 'app-set-payment',
  templateUrl: './set-payment.component.html',
  styleUrls: ['./set-payment.component.scss']
})
export class SetPaymentComponent {

}
