import { Component,  OnInit, inject} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MakePayment } from "@app/shared/interfaces/make-payment.interface";
import { StripeService } from "@app/shared/services/stripe.service";
import { ReviewPaymentComponent } from "../review-payment/review-payment.component";
import { dialogConfig } from "@app/core/const/dialog.const";
;

@Component({
  selector: "app-make-payment",
  templateUrl: "./make-payment.component.html",
  styles: `
    .radio-button {
      position: relative;
      height: 100%;
      padding: 1rem;
      border-radius: 9px;
      border: 1px solid rgba(143, 134, 147, 0.3);
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      cursor: pointer;  
    }
  `,
})
export class MakePaymentComponent {
  selectedPayment: string = "balance";
  fb = inject(FormBuilder);
  amount: number = 300;
  processingFee: number = 4;
  method!: {id: string, clientSecret: string, stripePaymentMethodId: string};

  #dialog = inject(MatDialog);
  #dialogRef = inject(MatDialogRef);
  #stripeService = inject(StripeService);

  paymentMethod(method: {id: string, clientSecret: string, stripePaymentMethodId: string}): void {
    this.method = method;
  }
  
  makePayment(): void {
    this.#dialog.open(ReviewPaymentComponent, {
      ...dialogConfig.addDialogStd
    })
  }

  #submitPayment(): void {
    const payload = this.#generatePayload();
    this.#stripeService.createPaymentIntent(payload).subscribe({
      next: resp => {
        console.log(resp);
      }
    })
  }

  #generatePayload(): MakePayment {
    let payload: MakePayment = {
      amount: this.amount,
      paymentMethodId : this.method.id,
    }

    return payload;
  }
}
