import { style } from '@angular/animations';
import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { injectStripe, StripePaymentElementComponent } from "ngx-stripe";
import {
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "@app/core/const/stripe.const";

@Component({
  selector: "app-payment-method-credit-card",
  template: `
    <form [formGroup]="form" class="d-grid gap-20" id="stripe-form">
      <div>
        <label class="stripe-font">Cardholder Name</label>
        <div class="mt-4">
          <app-roam-input
            [floatingLabel]="false"
            [name]="'Cardholder Name'"
            [ctr]="form.get('cardholder')"
            [ctrName]="'cardholder'"
            formControlName="cardholder" />
        </div>

      </div>
      <div>
        @if (elementsOptions.clientSecret) {
          <ngx-stripe-elements
            [stripe]="stripe"
            [elementsOptions]="elementsOptions">
            <ngx-stripe-payment />
          </ngx-stripe-elements>
        } @else {
          <app-loader-dot [size]="5"/>
        }
      </div>
    </form>
  `,
  styleUrl: "../add-payment-method/add-payment-method.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodCreditCardComponent {

  stripe = injectStripe(STRIPE_PUBLIC_KEY);
  
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  private readonly fb = inject(FormBuilder);

  elementsOptions: StripeElementsOptions = {
    locale: "en",
    appearance: {
      theme: "stripe",
      variables: {
        focusOutline: "none",
        borderRadius: "12px",
        colorBackground: "#ffffff",
        gridRowSpacing: "20px",
      },
      rules: {
        ".Input": {
          height: '40px',
          color: "#222222",
          padding: "11px 12px",
          fontSize: "14px",
          border: "1px solid #D6D3D7",
        },
        ".Input::placeholder": {
          color: "#949494",
        },
        ".Input:focus": {
          outline: "none !important",
          boxShadow: "none !important",
          borderColor: "#ccc !important",
        },
        ".Label": {
          fontSize: "14px",
          fontWeight: "500",
          marginBottom: "8px",
        },
        '.Error': {
          fontSize: '12px'
        },
        '.Input--invalid': {
          boxShadow: "unset"
        }
      },
    },
    
  };

  form = this.fb.group({
    cardholder: ['', [Validators.required]],
  });

}
