import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-method-bank',
  templateUrl: './payment-method-bank.component.html',
  styles:[`
    .bank-card {
      display: inline-block;
      padding: 24px 32px;
      border-radius: 9px;
      border: 1px solid rgba(143, 134, 147, 0.30);
      background:#ffffff;
      box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.12);
    }
  `]
})
export class PaymentMethodBankComponent {

  // SAMPLE
  public bankImage = './assets/sample/bank-america.png';
}
